/*contact-us*/

.contact-wrapper {
  min-height: 80%;
}

/*  body-component */

:root {
  --fullyopened: 240px;
  --closed: -212px;
  /* --semiclosed: -180px; */
}

.sideNav {
  transition: margin 200ms ease;
  z-index: 1;
  min-height: calc(100vh - 120px);
  border-bottom: 0px;
  border-right: 1px solid #ccc;
  padding: inherit;
  background: #fff;
}

.sideHeadings {
  height: calc(100vh - 113px);
  overflow-y: scroll;
  margin-bottom: 38px;
}

.sideHeadings::-webkit-scrollbar {
  display: none;
}

.closed {
  margin-left: var(--closed);
}

.semiclosed {
  margin-left: var(--semiclosed);
}

.expanded {
  background-color: lawngreen;
  margin-left: var(--fullyopened);
}

.closed button {
  margin-left: 240px;
  z-index: 1;
}

/* .sideHeadings {
  } */

.sideHeadings > button {
  padding: 0.5rem 1rem;
}

.sideNavMidSection {
  height: calc(100vh - 170px);
}

.sideNavToggleTwo {
  display: flex;
}

.sideNavToggleTwo button {
  display: block;
  margin-left: auto;
}

.sideSecondButton svg {
  display: block;
  margin: auto;
}

.main-body {
  display: flex;
}

.main {
  padding: 1rem;
  min-width: 80%;
  max-width: 100%;
  display: flex;
  min-height: 80vh;
  justify-content: center;
  align-items: center;
}

.menuItems {
  list-style: none;
  padding: 4px 0px 0px 4px;
  width: 200px;
}

.menu-links {
  /* padding: 8px 12px; */
  padding: 8px 12px 8px 0px;
  border-bottom: 1px solid #ccc;
}

.menu-links > a {
  color: #333;
  font-weight: bolder;
  text-decoration: none;
  font-size: 14px;
  padding: 0px 10px 0px 0px;
}

.menu-links > a > i {
  padding-right: 4px;
}

.sensors a {
  padding-left: 30px;
}

.activeMenu {
  color: #ff7538 !important;
}

/*footer-component */

.footer {
  text-align: center;
  border-top: 1px solid #bbb;
  background-color: white;
  z-index: 1;
  position: fixed;
  bottom: 0;
}

.footer p {
  font-weight: bold;
  padding-top: 14px;
}

/*header-component */

.header {
  width: 100%;
  height: 65px;
  background-color: #ff7538;
  display: flex;
  z-index: 100;
}

.header-logo {
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header-logo img {
  width: 140px;
}

.header-right-side {
  flex-grow: 1;
  /* margin-top: 21px; */
}

.header-profile {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-profile > .header-profile-info {
  margin-right: 10px;
  color: white;
}

.header-profile-info > h4 {
  margin: 0px;
  font-size: 18px;
  font-weight: 600;
}

.header-profile-info > h6 {
  margin: 0px;
  font-size: 14px;
  font-weight: 600;
}

.profile-icon-header {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #fff;
  padding: 4px 7px;
  overflow: hidden;
}

.profile-icon-header > img {
  position: relative;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  max-height: 40px;
}

.right-side-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 100%;
}

.menuIcon {
  background: transparent;
  border: none;
  color: white;
}

.menuIcon > i {
  font-size: 26px;
}

/*login-component*/

.disabled {
  color: #bbb !important;
}

.disabled a {
  cursor: no-drop;
}

.disabled.forgot-password-button {
  color: white !important;
}

@media screen and (max-width: 992px) {
  /* styles */
}

.password-eye {
  position: absolute;
  right: 2%;
  top: 30%;
  cursor: pointer;
}

.password-input {
  position: relative;
}

/*organization-component*/

.pagination li:first-child {
  display: none;
}

.pagination li:last-child {
  display: none;
}

.organization-search-input {
  border-radius: 5px 0px 0px 5px !important;
  margin-left: 1rem !important;
  padding-left: 1rem !important;
  border: 1px solid #ddd8d8 !important;
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%) !important;
}

.entry-select {
  border: 1px solid #ddd8d8;
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%) !important;
}

.organization-search-input:focus-visible,
.organization-status-select:focus-visible,
.entry-select:focus-visible {
  outline: none !important;
}

.organization-search-button {
  border-radius: 0px 5px 5px 0px !important;
  background-color: #ff7538 !important;
  color: white !important;
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%) !important;
}

.organization-status-button {
  border-radius: 5px 0px 0px 5px !important;
  background: #ff7538 !important;
  color: white !important;
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%) !important;
  cursor: context-menu !important;
}

.organization-status-select {
  border-radius: 0px 5px 5px 0px !important;
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%) !important;
  border: 1px solid #ddd8d8 !important;
}

.organization-plus-select {
  background: #ff7538 !important;
  border: none !important;
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%) !important;
}

.font-bold {
  font-weight: bold !important;
}

.color-orange {
  color: #ff7538 !important;
}

.pagination:focus {
  box-shadow: none !important;
  background: #ff7538 !important;
  color: white !important;
}

.organization-list-div {
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%) !important;
  border: 1px solid #ddd8d8 !important;
}

.ag-header-cell-text {
  font-weight: 700;
}

.ag-header-container {
  width: 100%;
}

/* sensors-component*/

.sensors-button {
  background: #ff7538 !important;
  border-radius: 2rem !important;
  border: none !important;
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%) !important;
}

.sub-menu {
  padding-left: 27px !important;
}

table {
  table-layout: fixed;
}

td {
  width: 33% !important;
}

.sensor .ag-header.ag-focus-managed.ag-pivot-off {
  display: none;
}

.elementDefault {
  color: green;
  font-size: 10px;
  font-weight: 600;
  margin-left: 5px;
}

.sensor-edit[disabled] {
  color: gray !important;
  pointer-events: none;
}

/*start of add sensor */

.add-update-heading {
  font-weight: bold;
  height: 45px;
  display: flex;
  align-items: center;
}

.add-update-right-wrapper {
  height: calc(100vh - 252px);
  padding: 13px;
  overflow-y: auto;
}

/* end of add sensor */

/* start add organization */

.addDeleteBtn {
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sensorBtn {
  display: flex;
  height: 100%;
  align-items: center;
}

/* end add organization */

/* start add user */

.profile-icon-add-user {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: aliceblue;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.add-user-profile-icon-div {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.profile-icon-add-user i {
  font-size: 40px;
}

/*sensor*/

.restore {
  display: flex !important;
  justify-content: flex-end !important;
}

.restoreIconDiv {
  background: #ff7538;
  padding: 4px 8px;
  border-radius: 4px;
  align-items: center;
}

.restore i {
  color: #fff;
  font-size: 18px;
  padding: 4px 4px;
}

.add-sensor-left input {
  margin-top: 50px;
  width: 80%;
}

/*.add-sensor-right input, .add-sensor-right select {
    border: 1px solid #f97538;
}*/

.theme-color-border {
  border: 1px solid #f97538;
  border-radius: 4px;
}

.add-sensor-right label {
  margin-right: auto;
  font-weight: 600;
}

.exportIconDiv {
  background: #ff7538;
  padding: 4px 8px;
  border-radius: 4px;
  align-items: center;
  width: max-content;
}

.exportIconDiv i {
  color: white;
}

.combineLogButton {
  background: #ff7538;
  padding: 4px 8px;
  border-radius: 4px;
  align-items: center;
  width: max-content;
  color: white;
  border: 1px solid #ff7538;
}

.bottom-export {
  font-size: 11px;
  font-weight: 600;
  margin-left: -1px;
}

/*sensor*/

/* end add user */

/* organizations*/

/* end organizations*/
/* assessment*/
/*end assessment*/
/* responsive */

@media screen and (max-width: 992px) {
  /* styles */
  .sideNav {
    position: absolute;
    background: white;
    z-index: 100;
  }

  .sideNav {
    min-height: 77vh;
  }

  /* sensor */
  .statusRestoreDiv {
    margin-top: 0px;
  }

  .add-sensor-left input {
    width: 100%;
  }

  .save-cancel-exit-btn {
    margin: auto 10px;
    flex-direction: column;
  }

  /* sensor */
}

@media screen and (max-width: 770px) {
  .restore {
    margin-top: 0px;
  }

  .statusRestoreDiv {
    margin-top: 14px !important;
  }

  .bottom-restore {
    margin-left: -10 px;
    margin-top: 54 px;
  }
}

@media screen and (max-width: 580px) {
  .restore {
    margin: 14px 0;
  }

  .restore i {
    padding: 4px 1px;
  }
}

.triangle-up {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 10px solid red;
  display: inline-block;
}

.triangle-up-yellow {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 10px solid #ffeb3bff;
  display: inline-block;
}

.square {
  height: 10px;
  width: 10px;
  display: inline-block;
}

.bg-red {
  background-color: red;
}

.bg-light-green {
  background-color: #90ee90;
}

.bg-blue {
  background-color: blue;
}

.bg-green {
  background-color: green;
}

.sideNav .menu-links a:hover {
  color: #ff7538 !important;
}

/* The switch - the box around the slider */
.combineSwitch {
  position: relative;
  display: inline-block;
  width: 45px;
  height: 20px;
}

/* Hide default HTML checkbox */
.combineSwitch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.combineSwitch .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.combineSwitch .slider:before {
  position: absolute;
  content: "";
  height: 13px;
  width: 13px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.combineSwitch input:checked + .slider {
  background-color: #ff7538;
}

.combineSwitch input:focus + .slider {
  box-shadow: 0 0 1px #ff7538;
}

.combineSwitch input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.combineSwitch .slider.round {
  border-radius: 34px;
}

.combineSwitch .slider.round:before {
  border-radius: 50%;
}

.training-chart-tooltip div {
  font-size: 12px;
  font-weight: bold;
}

.training-chart-tooltip .graph-image {
  max-width: 100%;
  max-height: 100px;
}

/*About us*/
.about-link {
  display: block;
  font-weight: bold;
  font-size: 18px;
  text-decoration: none;
}

.students-select-type .SelectBox {
  width: calc(100% - 125px);
}

.students-select .SelectBox {
  width: calc(100% - 160px);
}

.ellipsis-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.main-form-section {
  box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
  padding: 10px;
  margin-bottom: 10px;
}

.agreement-model-header {
  border: 1px solid #fd7438;
  background: #fd7438;
}

.agreement-model-header .text {
  font-size: 14px;
  font-style: normal;
}

.agreement-model-body {
  max-height: calc(100vh - 300px);
  overflow: auto;
}

.agreement-model-body .paragraph {
  font-size: 14px;
}

.main-form-section .heading {
  margin-bottom: 10px;
  font-weight: 600;
  display: inline-block;
}

.dropdown-fade {
  border: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  outline: none;
  min-width: 130px !important;
}

.custom-arrow {
  font-size: 15px;
  color: rgba(0, 0, 0, 0.4);
  position: absolute;
  right: 40px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}
@media (max-width: 1700px) {
  .custom-arrow {
    right: 40px;
  }
}

.unit-select {
  position: absolute;
  top: 0;
  height: 38px;
  right: 12px;
  width: 100%;
  align-content: center;
  max-width: 150px;
}

.unit-label {
  position: absolute;
  top: 0;
  height: 38px;
  right: 60px;
  width: 100%;
  align-content: center;
  max-width: 100px;
  padding: 0 24px 0 12px;
  border-left: 1px solid #0003;
}
